import { nthChild } from 'glamor'

// App components
import Button from '../components/Button'

import { rhythm } from '../utils/typography'
import { themeStyles, colors, metrics, presets } from '../utils/theme'

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${colors.lightGrey}`,
    padding: rhythm(1),
    width: '100%',
    maxWidth: '500px',
    ...themeStyles.shadow,
  },
  input: {
    padding: rhythm(1 / 2),
    marginBottom: rhythm(1 / 2),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    alignSelf: 'stretch',
    paddingTop: metrics.defaultPadding,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  textHolder: {
    padding: metrics.defaultPadding,
    paddingTop: 0,
    minWidth: '300px',
    [presets.Mobile]: {
      minWidth: '400px',
    },
    width: '100%',
    flex: 1,
  },
}

export const Freebie = ({
  image,
  content,
  link,
  buttonText,
  href,
  noMargin = false,
}) => {
  return (
    <div
      css={[
        styles.container,
        nthChild('2n + 1', { backgroundColor: colors.lightGrey }),
        { paddingTop: noMargin ? 0 : null },
      ]}
      key={link}
    >
      <img src={image} css={{ width: '100%', maxWidth: '300px', margin: 0 }} />
      <div css={styles.textHolder}>
        {content}
        <div css={{ marginTop: rhythm(1) }}>
          <Button text={buttonText} to={link} href={href} />
        </div>
      </div>
    </div>
  )
}
