import React from 'react'

import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'
import { Freebie } from '../../components/Freebie'

// App content
import fivethingsInvisalign from '../../assets/invis-5things.png'
import tenThingsCover from '../../assets/10Things3DCover.png'

// App utils
import { themeStyles, colors } from '../../utils/theme'

const matchPColor = { color: 'hsla(0,0%,0%,0.6)' }

const freebies = [
  {
    image: tenThingsCover,
    buttonText: 'Get Your FREE Report',
    link: '/resources/free/top-ten-things-you-must-know-before-choosing-your-familys-orthodontist',
    content: (
      <div>
        <p css={{ marginBottom: 0, color: colors.brightBlue }}>
          Are you looking for a Family Friendly Orthodontist?
        </p>
        <h2 css={matchPColor}>
          Top 10 Things you Must Know Before Choosing Your Family’s Orthodontist
        </h2>
        <p>
          Choosing an orthodontist for you family can be stressful. You want to
          ensure a great fit across many vectors: Experience, Cost, Kindness,
          etc. How are you supposed to make this choice? We’ve come up with the
          top 10 things you must know before choosing your family’s
          orthodontist. We’d love to share them with you!
        </p>
      </div>
    ),
  },
  {
    image: fivethingsInvisalign,
    buttonText: 'Get Your FREE Report',
    link: '/resources/free/top-five-things-you-need-to-know-about-invisalign',
    content: (
      <div>
        <p css={{ marginBottom: 0, color: colors.brightBlue }}>
          Are you considering braces for yourself or your child?
        </p>
        <h2 css={matchPColor}>
          Top 5 Things You Need To Know About Invisalign
        </h2>
        <p>
          You’ve probably heard of invisalign®, the clear removable trays that
          many of our patients here in Rochester, MN use to straighten their
          teeth with minimal changes to their lifestyle. With the invisalign
          system, Dr. Collins can move your teeth gradually without the
          complications of metal brackets and wires.
        </p>
        <p>
          Although invisalign is a wonderful tool that orthodontists can use to
          straighten your teeth, there are several things you MUST KNOW prior to
          starting invisalign treatment. If you decide to start invisalign
          treatment without knowing these items in advance, your treatment
          outcome may not only end up less than ideal, but it could end up
          destructive to the health of your teeth and smile.
        </p>
      </div>
    ),
  },
]

class Page extends React.Component {
  render() {
    const freebieElements = freebies.map((item) => <Freebie {...item} />)
    return (
      <Layout location={this.props.location}>
        <div css={{ ...themeStyles.contentWidth }}>
          <PageTitle
            title="Free Reports and Articles"
            bannerKey={'RESOURCES'}
          />
          {freebieElements}
        </div>
      </Layout>
    )
  }
}

export default Page
